import React, {Component} from "react";
import { styled } from "@mui/material/styles";
import { RootState } from '../../store/configure-store';
import { compose } from "redux";
import { connect } from 'react-redux';
import { AccessDeniedAlert } from "../../components/access-denied";
import { Loader } from '../../components/loaders/loader';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import { OverlayScrollbarsComponent } from "overlayscrollbars-react";
import TablePagination from '@mui/material/TablePagination';
import {
    apiInfoFetchList,
    apiInfoFetchCurrencyList,
    apiInfoFetchPMMethodsList,
} from '../../actions/apiInfo';
import {getPaymentSystemsListRequest} from '../../actions/paymentSystems';
// import {FilterListSvg} from "../../components/svg/filter-list-svg";
// import { TableFilter } from "../../components/table-filter";
// import Menu from '@mui/material/Menu';
// import SvgIcon from '@mui/material/SvgIcon';
import {Button} from '../../components/button';
// import AddLinkIcon from '@mui/icons-material/AddLink';
import DownloadIcon from '@mui/icons-material/Download';
import {CopyClipboardButton} from '../../components/clipboard-copy/button-copy';
import Tooltip from '@mui/material/Tooltip';


const WrapperPage = styled('div')(({theme}) => ({}));

const PageContainer = styled('div')(({theme}) => ({
    padding: '40px 40px 0 40px',
    background: theme.palette.background.paper,
    height: 'calc(100% - 80px)',
    position: 'relative',

    '@media(max-width: 768px)' : {
        padding: '16px',
        height: '100%'
    }
}));

const DepositMainHeaderText = styled('div')(({ theme }) => ({
    fontSize: '30px',
    fontWeight: '500',
    lineHeight: '38px',
    marginRight: '24px',
    color: theme.palette.text.primary,

    '@media(max-width: 768px)' : {
        fontSize: '20px',
    }
}));

const DepositMainHeader = styled('div')({
    display: 'flex',
    marginBottom: '32px',

    '@media(max-width: 768px)' : {
        justifyContent: 'space-between',
        marginBottom: '24px',
    }
});

const DepositMainSecondRow = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    marginBottom: '32px',

    '@media(max-width: 1260px)' : {
        flexWrap: 'wrap',
        marginBottom: '24px',

    }
});

const DepositMainSecondRowRightBlock = styled('div')({
    display: 'flex',
    justifyContent: 'space-between',
    width: '100%'
});

// const DepositMainSecondRowLeftButtons = styled('div')({
//     '@media(max-width: 768px)' : {
//         '& button': {
//             boxSizing: 'border-box',
//             fontSize: '12px',
//             height: '30px',
//             padding: '0 12px',
//             marginRight: '11px',
//             '&:last-child':{
//                 marginRight: '0px',
//             }
//         }
//     }
// });

const StyledTableHead = styled(TableHead)(({ theme }) => (`
    background: ${theme.palette.background.default};
    position: sticky;
    z-index: 10;
    top: 0;
    & th{
        border-left: ${theme.palette.mode === 'dark' ? '1px solid rgba(81, 81, 81, 1)' : '1px solid rgba(224, 224, 224, 1)'};
    }   
`));

const StyledPaperTable = styled(Paper)(({ theme }) => (`
    border-radius: 0;
    border: 1px solid ${ theme.palette.mode === 'light' ? '#E3E8EC' : 'rgba(255,255,255, .2)'};
    box-sizing: border-box;
    box-shadow: none;
`));

const StyledTableContainer = styled(TableContainer)(({ theme }) => (`
    position: relative;
    .MuiPaper-root{
        border-radius: 0;
        box-sizing: border-box;
        box-shadow: none;
    }
    & .overlay-scroll-table{
        max-height: calc(100vh - 260px - 56px);
        @media(max-width: 767px){
            max-height: calc(100vh - 250px - 56px);
        }; 
    }
`));

// const TextDescription = styled('div')(({ theme }) => ({
//     color: theme.palette.text.primary,
// }));

// const TableCellWithActiveClass = styled(TableCell)(({ theme }) => ({
//     '&.activeFilterClass':{
//         background: '#38AA72',
//         '& div':{
//             color: '#fff',
//             '& svg path':{
//                 fill: '#fff',
//             }
//         }
//     }
// }));

// const TableCellContent = styled('div')(`
//     display: flex;
//     align-items: center;
// `);

// const IconWrapper = styled('div')(`
//     margin-left: 8px;
//     cursor: pointer;
//     display: flex;
//     align-items: center;
// `);

// const StyledMenu = styled(Menu)(({ theme }) => (`
//     .MuiPaper-root{
//         border-radius: 0;
//         & > .MuiList-root{            
//             padding: 0 0 8px 0;
//         }
//     }
// `));

// const WithSortedTableCellContent = styled(TableCellContent)(({ theme }) => (`
//     position: relative;
//     // padding-right: 30px;
//     min-width: 120px;
//     widt: 25%;
//     box-sizing: border-box;
//     justify-content: flex-start;
// `));

// const SortButtons = styled('div')(({ theme }) => (`
//     position: absolute;
//     right: 0;
//     top: -10px;
// `));

// const SortButtonsSongleToLeft = styled(SortButtons)(({ theme }) => (`
//     position: absolute;
//     right: 0;
//     top: -10px
// `));

// const SortButtonTop = styled('div')(({ theme }) => (`
//     & svg{ 
//         cursor: pointer;
//         bottom: -7px;
//         position: relative;
//         width: 12px;
//         height: 12px;
//     }
// `));

// const SortButtonBottom = styled('div')(({ theme }) => (`
//     & svg{ 
//         cursor: pointer;
//         position: relative;
//         top: -5px;
//         width: 12px;
//         height: 12px;
//     }
// `));

const WrapActions = styled('div')(({ theme }) => (`
    display: flex;
    justify-content: center;
`));

// type data = {
//     'paymentSystem.id[]': number,
//     'page': number,
//     'way[]': string,
//     'currency[]': Array<string>,
//     'paymentMethod[]': Array<string>,
//     'order[dateLastTransaction]': string,
//     'itemsPerPage': number,
// }

type PropsType = {
    isLoading: boolean, 
    APILIst: Array<any>,
    listCurrency: Array<string>,
    litPSMethod: Array<string>,
    totalItems: number,
    routsUser: Array<any>,
    userRoles: Array<any>,
    isSuperadmin: number,
    paymentSystemsList: Array<any>,
    apiInfoFetchList: (data: any) => void,
    apiInfoFetchCurrencyList: () => void,
    apiInfoFetchPMMethodsList: () => void,
    getPaymentSystemsListRequest: () => void,
}

type StateProps = {
    page: number,
    // rowsPerPage: 100,
    anchorElWay: null | any,
    way: Array<string>,
    anchorElCurrency: null | any,
    currency: Array<string>,
    anchorElPaymentMethod: null | any,
    paymentMethod: Array<string>,
    anchorElPaymentSystems: null | any,
    paymentSystems: Array<string>,
    oeder: string,
    itemsPerPage: number,
}

// const filterItemsWay = [
//     {title: 'Deposit', value: 'deposit'}, 
//     {title: 'Withdraw', value: 'withdraw'}, 
// ];


export class TransactionsReportsCatalog extends Component<PropsType, StateProps> {
    constructor(props) {
        super(props);

        this.state = {
            page: 0,
            // rowsPerPage: 100,
            anchorElWay: null,
            way: [],
            anchorElCurrency: null,
            currency: [],
            anchorElPaymentMethod: null,
            paymentMethod: [],
            anchorElPaymentSystems: null,
            paymentSystems: [],
            oeder: 'desc',
            itemsPerPage: 50,
        }
    };

    handleChangePage = (event: React.MouseEvent<HTMLElement>, newPage: number) => {
        this.setState({page: newPage}, () => {
            this.updateData();
        });
    };

    handleChangeRowsPerPage = (event: React.ChangeEvent<HTMLInputElement>) => {
        this.setState({page: 0, itemsPerPage: parseInt(event.target.value)}, () => {this.updateData()})
    };

    handleSearchSubmit = (prop) => (value) => {
        //@ts-ignore
        this.setState({[prop]: value, page: 0}, () => {this.updateData()});
    };
    handleCancelSearch = (prop) => () => {
        //@ts-ignore
        this.setState({[prop]: '', page: 0}, () => {this.updateData()});
    };

    handleWayMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElWay: e.currentTarget});
    }
    handleWayMenuClose = () => {
        this.setState({anchorElWay: null});
    }
    onChangeWayFilter = (data) => {
        this.setState({way: data}, () => {this.updateData()});
        this.handleWayMenuClose();
    }

    handleCurrencyMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElCurrency: e.currentTarget});
    }
    handleCurrencyMenuClose = () => {
        this.setState({anchorElCurrency: null});
    }
    onChangeCurrencyFilter = (data) => {
        this.setState({currency: data}, () => {this.updateData()});
        this.handleCurrencyMenuClose();
    }

    handlePaymentMethodMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentMethod: e.currentTarget});
    }
    handlePaymentMethodMenuClose = () => {
        this.setState({anchorElPaymentMethod: null});
    }
    onChangePaymentMethodFilter = (data) => {
        this.setState({paymentMethod: data}, () => {this.updateData()});
        this.handlePaymentMethodMenuClose();
    }

    handlePaymentSystemsMenuOpen = (e: React.MouseEvent<HTMLElement>) => {
        this.setState({anchorElPaymentSystems: e.currentTarget});
    }
    handlePaymentSystemsMenuClose = () => {
        this.setState({anchorElPaymentSystems: null});
    }
    onChangePaymentSystemsFilter = (data) => {
        this.setState({paymentSystems: data}, () => {this.updateData()});
        this.handlePaymentSystemsMenuClose();
    }

    handleClickSortTopUpdate = () => {
        this.setState({oeder: 'asc'}, () => this.updateData())
    };

    handleClickSortBottomUpdate = () => {
      this.setState({oeder: 'desc'}, () => this.updateData())
    };

    handleClearFilters = () => {
        this.setState({
                page: 0,
                anchorElWay: null,
                way: [],
                anchorElCurrency: null,
                currency: [],
                anchorElPaymentMethod: null,
                paymentMethod: [],
                anchorElPaymentSystems: null,
                paymentSystems: [],
                oeder: 'desc',
            }, 
            () => {this.updateData()}
        )
    }
    
    componentDidMount() {
        const {
            // routsUser, 
            userRoles, 
            isSuperadmin,
        } = this.props;

        if(userRoles.some(role => role.name === 'IntCat') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin){
            // const {
            //     // apiInfoFetchCurrencyList,
            //     // apiInfoFetchPMMethodsList,
            //     // getPaymentSystemsListRequest,
            // } = this.props;

            // this.updateData();
            // apiInfoFetchCurrencyList();
            // apiInfoFetchPMMethodsList();
            // getPaymentSystemsListRequest();
        }
    }

    updateData = () => {
        const {
            paymentSystems,
            page,
            way,
            currency,
            paymentMethod,
            oeder,
            itemsPerPage,
        } = this.state;

        const {
            apiInfoFetchList,
        } = this.props;

        const data = {
            'paymentSystem.id[]': paymentSystems,
            'page': page + 1,
            'way[]': way,
            'currency[]': currency,
            'paymentMethod[]': paymentMethod,
            'order[dateLastTransaction]': oeder,
            'itemsPerPage': itemsPerPage,
        };

        Object.keys(data).forEach(key => {
            if (!data[key]) delete data[key];
        });

        apiInfoFetchList(data);
    }

    isClearedFilters = () => {
        const {                
            page,
            way,
            currency,
            paymentMethod,
            paymentSystems,
        } = this.state
        return !(page > 0 || way.length || currency.length || paymentMethod.length || paymentSystems.length)
    }

    render() {
        const {
            isLoading,
            // APILIst,
            // routsUser, 
            userRoles, 
            isSuperadmin,
            // totalItems,
            // listCurrency,
            // litPSMethod,
            // paymentSystemsList,
        } = this.props

        // const {
        //     // page,
        //     // rowsPerPage,
        //     // itemsPerPage,
        //     // way,
        //     // anchorElWay,
        //     // currency,
        //     // anchorElCurrency,
        //     // paymentMethod,
        //     // anchorElPaymentMethod,
        //     // paymentSystems,
        //     // anchorElPaymentSystems,
        //     //...
        // } = this.state;

        const accessedRole = userRoles.some(role => role.name === 'IntCat') || userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;
        // const accessedRoleCreate = userRoles.some(role => role.name === 'Admin') || !!isSuperadmin;

        // const isWayFilterOpen = Boolean(anchorElWay);
        // const isCurrencyFilterOpen = Boolean(anchorElCurrency);
        // const isanchorElPaymentMethodFilterOpen = Boolean(anchorElPaymentMethod);
        // const isanchorElPaymentSystemsFilterOpen = Boolean(anchorElPaymentSystems);        

        return (
            <WrapperPage>
                <PageContainer>

                    {!accessedRole && <AccessDeniedAlert/>}

                    {accessedRole &&
                        <>
                            <DepositMainHeader>
                                <DepositMainHeaderText>Transactions catalog files</DepositMainHeaderText>
                            </DepositMainHeader>
                            <DepositMainSecondRow>
                                <DepositMainSecondRowRightBlock>
                                    {/* <DepositMainSecondRowLeftButtons>
                                    <Button
                                        onClick={this.handleClearFilters}
                                        variant="outlined"
                                        disabled={this.isClearedFilters()}
                                    >
                                        Clear Filters
                                    </Button>
                                    </DepositMainSecondRowLeftButtons> */}
                                </DepositMainSecondRowRightBlock>
                            </DepositMainSecondRow>
                            <StyledPaperTable>                               
                                <StyledTableContainer>

                                    <OverlayScrollbarsComponent
                                        className='overlay-scroll-table'
                                        options={{
                                            scrollbars: { visibility: 'visible' } 
                                        }}
                                    >

                                        <Table>

                                            <StyledTableHead>
                                                <TableRow>

                                                    <TableCell width={150}>
                                                        Date
                                                    </TableCell>

                                                    <TableCell width={150}>
                                                        User
                                                    </TableCell>

                                                    <TableCell width={400}>
                                                        Name
                                                    </TableCell>

                                                    <TableCell width={400}>
                                                        Description
                                                    </TableCell>

                                                    <TableCell>
                                                        Action
                                                    </TableCell>
                
                                                </TableRow>
                                            </StyledTableHead>

                                            <TableBody>

                                                <TableRow key={Math.random()}>
                                                    <TableCell>
                                                        12.10.2024
                                                    </TableCell>

                                                    <TableCell>
                                                        m.serpokryl
                                                    </TableCell>

                                                    <TableCell>
                                                        id1231kmn3m12n33mnbn24n23b4mn24
                                                    </TableCell>

                                                    <TableCell>
                                                        --
                                                    </TableCell>

                                                    <TableCell width={250}>
                                                        <WrapActions>
                                                            <Button
                                                                variant="outlined"
                                                            >
                                                                <DownloadIcon sx={{marginRight: '0px'}}/>
                                                            </Button>
                                                            
                                                            <Tooltip title='Copy' placement="top">
                                                                <CopyClipboardButton 
                                                                    sx={{marginLeft: '16px'}} 
                                                                    data={`Copy 111`}
                                                                />
                                                            </Tooltip>
                                                        </WrapActions>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key={Math.random()}>
                                                    <TableCell>
                                                        11.10.2024
                                                    </TableCell>

                                                    <TableCell>
                                                        s.synebok
                                                    </TableCell>

                                                    <TableCell>
                                                        id87yd78a7eywe786a7ea87e67a8w7676aw8eawea
                                                    </TableCell>

                                                    <TableCell>
                                                        --
                                                    </TableCell>

                                                    <TableCell width={250}>
                                                        <WrapActions>
                                                            <Button
                                                                variant="outlined"
                                                            >
                                                                <DownloadIcon sx={{marginRight: '0px'}}/>
                                                            </Button>

                                                            <CopyClipboardButton 
                                                                sx={{marginLeft: '16px'}} 
                                                                data={`Copy 222`}
                                                            />
                                                        </WrapActions>
                                                    </TableCell>
                                                </TableRow>

                                                <TableRow key={Math.random()}>
                                                    <TableCell>
                                                        10.10.2024
                                                    </TableCell>

                                                    <TableCell>
                                                        a.sekach
                                                    </TableCell>

                                                    <TableCell>
                                                        id456mmlklmklmk646mklmk4l56lk4m6lk4m5l64klm56l456
                                                    </TableCell>

                                                    <TableCell>
                                                        --
                                                    </TableCell>

                                                    <TableCell width={250}>
                                                        <WrapActions>
                                                            <Button
                                                                variant="outlined"
                                                            >
                                                                <DownloadIcon sx={{marginRight: '0px'}}/>
                                                            </Button>

                                                            <CopyClipboardButton 
                                                                sx={{marginLeft: '16px'}} 
                                                                data={`Copy 333`}
                                                            />
                                                        </WrapActions>
                                                    </TableCell>

                                                </TableRow>
                                                {/* {(APILIst || []).map(itemAPI => {
                                                    return (
                                                        <TableRow key={Math.random()}>
                                                            <TableCell>
                                                                {itemAPI.paymentSystem?.name}
                                                            </TableCell>

                                                            <TableCell>
                                                                {itemAPI.way}
                                                            </TableCell>

                                                            <TableCell>
                                                                {itemAPI.currency}
                                                            </TableCell>

                                                            <TableCell>
                                                                {itemAPI.paymentMethod}
                                                            </TableCell>

                                                            <TableCell>
                                                                {itemAPI.dateLastTransaction}
                                                            </TableCell>
                                                        </TableRow>
                                                    )
                                                })} */}
                                            </TableBody>
                                        </Table>

                                    </OverlayScrollbarsComponent>

                                </StyledTableContainer>     

                                <TablePagination
                                    // rowsPerPageOptions={[itemsPerPage]}
                                    rowsPerPageOptions={[20, 50, 75, 100]}
                                    component="div"
                                    count={100500}
                                    // count={totalItems}
                                    // rowsPerPage={itemsPerPage}
                                    rowsPerPage={20}
                                    // page={page}
                                    page={0}
                                    onPageChange={this.handleChangePage}
                                    onRowsPerPageChange={this.handleChangeRowsPerPage}
                                />
                            </StyledPaperTable>
                        </>
                    }

                    { isLoading && <Loader/> }
    
                </PageContainer>
            </WrapperPage>
        )
    }

}

export default compose(
    connect((state: RootState) => ({
        isLoading: state.apiInfo.isLoading, 
        APILIst: state.apiInfo.apiInfoList['hydra:member'],
        listCurrency: state.apiInfo.listCurrency,
        litPSMethod: state.apiInfo.litPSMethod,
        totalItems: state.apiInfo.apiInfoList['hydra:totalItems'],
        routsUser: state.currentUser.userData.routs,
        userRoles: state.currentUser.userData.roles,
        isSuperadmin: state.currentUser.userData.superadmin,
        paymentSystemsList: state.paymentSystems.paymentSystemsList['hydra:member'],
    }), {
        apiInfoFetchList,
        apiInfoFetchCurrencyList,
        apiInfoFetchPMMethodsList,
        getPaymentSystemsListRequest,
    }),
)(TransactionsReportsCatalog)